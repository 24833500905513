.alavalikko::-webkit-scrollbar {
  display: none;
}

.alavalikko{
  z-index: 0;
  scrollbar-width: 0;
}
/* .testi {
  bottom: "55px";
} */
.textcontainer::-webkit-scrollbar {
  display: none;
}

.alavalikkocarddata {
  z-index: 0;
  scrollbar-width: 0;
}
.alavalikkocarddata::-webkit-scrollbar {
  z-index: 0;
  scrollbar-width: 0;
}

.cardimage{
  object-fit: cover;
  width: 100%;
  height: 70px;
}
