.texti{
  position: fixed;
  margin-left: 70px;
  margin-top: 20px;
  color: white;
  font-size: 20px;
  /* font-style: italic; */
  z-index: 1000;
}

/* .containeri {
  position: absolute;
  z-index: 1000;
} */
