.em-container {
  position: fixed;
  width: 100%;
  height: 25px;
  top: 60px;
  background-color: #fb2d2dd5;
  z-index: 1;
  text-align: center;
}
.em-announcement {
  margin-top: 1px;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  text-shadow: 1px 1px black;
}
