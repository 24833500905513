#containerr::-webkit-scrollbar {
  display: none;
}

p::-webkit-scrollbar {
  display: none;
}

.titleheader:hover{
  cursor: pointer;
}

.closebtn:hover{
  background-color: black;
}