.map-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.mapboxgl-ctrl-icon {
  margin: 0px 0px 0px 0px;
}

.mapboxgl-ctrl-group{
  position: relative;
  top: 90px;
  left: 10px;
  z-index: 1;
}
