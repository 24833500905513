.specific-form {
  margin-top: 50px;
  text-align: center;
}

.answer-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.answer-line {
  display: flex;
  flex-direction: row;
}

.answer-line input {
  position: absolute;
  right: 40px;
  /* padding-left: 20px; */
  margin-top: 3px
}

.answer-line p {
  /* max-width: 250px; */
  margin-left: 30px;
}

.question-text {
  padding-top: 30px;
}